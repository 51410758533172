<template>
  <div id="new-role" class="d-flex h-100 d-flex align-items-center justify-content-center">
    <b-card no-body style="width: 400px">
      <b-card-header class="d-flex align-items-center" header-bg-variant="transparent"
        >Dodaj rolę
        <div class="ml-auto">
          <b-button
            class="ml-auto mr-2"
            variant="light"
            v-if="!isObjectEmpty(form)"
            @click="clearForm"
            >Wyczyść</b-button
          >

          <b-button class="text-white" @click="$router.go(-1)">{{ $t("BACK") }}</b-button>
        </div>
      </b-card-header>
      <b-card-body>
        <b-form ref="newRoleForm" @submit.prevent="newRoleForm">
          <!-- LABEL FORM -->
          <b-form-group class="my-3" label-cols-lg="3" label-size="sm" :label="$t('TABLE.LABEL')">
            <b-form-input
              :placeholder="$t('TABLE.LABEL')"
              v-model="$v.form.name.$model"
              aria-describedby="formNewRoleNameFeedback"
              :state="validateState('name')"
            ></b-form-input>
            <b-form-invalid-feedback id="formNewRoleNameFeedback">
              Name jest wymagany
            </b-form-invalid-feedback>
          </b-form-group>
          <!--  ROLE FORM-->
          <b-form-group
            prepend="ROLE_"
            class="my-3"
            label-cols-lg="3"
            label-size="sm"
            :label="$t('TABLE.NAME')"
          >
            <b-form-input
              :placeholder="$t('TABLE.NAME')"
              v-model="$v.form.label.$model"
              aria-describedby="formNewRoleLabelFeedback"
              :state="validateState('label')"
            ></b-form-input>
            <b-form-invalid-feedback id="formNewRoleLabelFeedback">
              Label jest wymagany</b-form-invalid-feedback
            >
          </b-form-group>
          <!-- DURATION FORM-->
          <b-form-group label-cols-lg="3" label-size="sm" :label="$t('TABLE.DURATION')">
            <time-range-input
              :reset="resetTimeRange"
              v-model="$v.form.duration.$model"
              :state="form.duration ? true : null"
              :allow-empty="false"
            />
          </b-form-group>

          <div class="buttons w-100 mt-2">
            <b-button
              type="submit"
              variant="primary"
              class="ml-auto d-block text-white"
              :class="{ disabled: $v.form.$invalid }"
            >
              {{ $t("ADD") }}
            </b-button>
          </div>
        </b-form>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import required from "vuelidate/lib/validators/required";
import TimeRangeInput from "@/components/TimeRangeInput/TimeRangeInput.vue";
import { NEW_ROLE } from "@/core/store/Roles/role.module";

export default {
  name: "Role",
  components: { TimeRangeInput },
  data() {
    return {
      form: {
        name: null,
        label: null,
        duration: null,
      },
      resetTimeRange: false,
    };
  },
  validations: {
    form: {
      name: {
        required,
      },
      label: {
        required,
      },
      duration: {
        required,
      },
    },
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    clearForm() {
      this.$refs.newRoleForm.reset();
      this.resetTimeRange = !this.resetTimeRange;
      this.form = { name: null, label: null, duration: null };
      this.timeRange = null;
    },
    newRoleForm() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }
      const formData = {
        ...this.form,
        name: `ROLE_${this.form.name}`,
      };
      this.$store
        .dispatch(NEW_ROLE, formData)
        .then(() => {
          this.toastSuccess("Rola dodana pomyślnie", "Sukces");
          this.clearForm();
          this.$nextTick(() => {
            this.$v.form.$reset();
          });
        })
        .catch((err) => {
          this.toastError("Error", err);
        });
    },
  },
};
</script>
