<template>
  <b-input-group class="timeRangeInput trans" ref="TimeRangeInput">
    <multi-select
      style="height: 35px !important"
      :options="['infinite', 'instant', 'range']"
      :searchable="false"
      :class="{ invalid: state === false }"
      placeholder="Wybierz opcje"
      open-direction="below"
      selectLabel="Naciśnij Enter"
      :deselectLabel="deselectLabel"
      selectedLabel="Wybrano"
      v-model="durationType"
      :allow-empty="allowEmpty"
      @input="emitModel"
    >
      <template slot="option" slot-scope="props"> {{ $t(props.option.toUpperCase()) }} </template>
      <template slot="singleLabel" slot-scope="props">
        {{ $t(props.option.toUpperCase()) }}
      </template>
    </multi-select>
    <b-input-group>
      <b-form-input
        class="my-3 rounded"
        :class="{ 'is-invalid': !value }"
        :placeholder="(defaultValue && defaultValue.expires_after) || 'Ilość dni'"
        type="number"
        v-if="durationType === 'instant'"
        v-model="value"
        @input="emitModel"
      />
      <date-picker
        :clearable="false"
        class="my-3 w-100"
        range
        :class="{ invalid: !value }"
        v-if="durationType === 'range'"
        valueType="format"
        v-model="value"
        @input="emitModel"
      />
    </b-input-group>
  </b-input-group>
</template>

<script>
export default {
  name: "timeRangeInput",
  props: {
    reset: Boolean,
    state: {
      type: Boolean,
      default: false,
    },
    deselectLabel: {
      type: String,
      default: "Naciśnij Enter aby usunąć",
    },
    allowEmpty: {
      type: Boolean,
      default: true,
    },
    defaultValue: {
      type: Object,
    },
  },
  data() {
    return {
      durationType: null,
      value: null,
      valid: false,
    };
  },
  methods: {
    emitModel() {
      if (this.durationType === "infinite") {
        this.$emit("input", { type: "infinite" });
      } else if (this.durationType === "instant") {
        this.$emit("input", {
          type: this.durationType,
          expires_after: this.value,
        });
      } else if (this.durationType === "range") {
        this.$emit("input", {
          type: this.durationType,
          begins_on: this.value && `${this.value[0]} 24:00`,
          expires_on: this.value && `${this.value[1]} 24:00`,
        });
      }
    },
  },
  watch: {
    durationType(val) {
      this.emitModel();
      if (val !== this.defaultValue.type) this.value = null;
    },
    reset() {
      this.durationType = null;
      this.value = null;
      this.emitModel();
    },
    defaultValue() {
      const { expires_after, begins_on, expires_on } = this.defaultValue;
      this.durationType = this.defaultValue.type;
      if (expires_after) {
        this.value = Number(expires_after);
      }
      if (begins_on && expires_on) {
        this.value = [begins_on, expires_on];
      }
    },
  },
  created() {},
};
</script>

<style lang="scss">
.invalid {
  border: 1px solid $danger;
  border-radius: $border-radius;
}
</style>
